import React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import Icon from "../../components/custom-widgets/icon";
import Button from "../../components/custom-widgets/button";
import List from "../../components/custom-widgets/list";
import Tab from "../../components/tab/custom-tab";

const OpenBankAccountOnlineSpanish = () => {
  const title = "Abrir una cuenta bancaria en línea";
  const description =
    "Conozca todos los requisitos para abrir una cuenta bancaria en línea con WaFd Bank, elija el tipo de cuenta y comience a disfrutar de los beneficios.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/open-bank-account-online/hero-open-an-account-013023-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "open-bank-account-spanish-hero",
    ...getHeroImgVariables(imgData),
    altText: "Pareja en la cafetería mirando el teléfono celular.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Abra fácilmente una cuenta nueva personal bancaria de WaFd en línea en solo minutos."
          }
        }
      ]
    }
  };

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: "Open Bank Account Online"
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/abrir-cuenta-bancaria-en-linea"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-open-an-account-013023.jpg"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: "true",
      title: "Abrir Una Cuenta Bancaria En Linea"
    }
  ];

  const freeCheckingListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Depósito inicial de solo $25"
      },
      {
        id: 2,
        text: "Sin balance mínimo"
      },
      {
        id: 3,
        text: "Sin cuotas mensuales de mantenimiento"
      },
      {
        id: 4,
        text: "Acceso a miles de cajeros automáticos sin cargo"
      },
      {
        id: 5,
        text: "Banca en línea"
      },
      {
        id: 6,
        text: "Pago de Facturas y transferencias externas"
      },
      {
        id: 7,
        text: "Banca móvil"
      },
      {
        id: 8,
        text: "Pagos de persona-a-persona"
      },
      {
        id: 9,
        text: "Protección contra sobregiros"
      },
      {
        id: 10,
        text: "Cheques seguros"
      }
    ]
  };

  const interestCheckingListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Depósito inicial de solo $25"
      },
      {
        id: 2,
        text: 'Consiga una Caja Fuerte de Almacenamiento de 3x5" (Sujeto a disponibilidad)'
      },
      {
        id: 3,
        text: "Ahorre 5 ¢/gal en combustible Shell"
      },
      {
        id: 4,
        text: "Servicios de Telesalud 24/7"
      },
      {
        id: 5,
        text: "Monitoreo contra robo de identidad, historial crediticio, y web oscura"
      },
      {
        id: 6,
        text: "Reembolso por robo de identidad por hasta $1 millón"
      },
      {
        id: 7,
        text: "Hasta $800 por reclamo en protección de su teléfono móvil (Aplican deducibles de $50)"
      },
      {
        id: 8,
        text: "Asistencia en el camino de 24-Horas"
      },
      {
        id: 9,
        text: "Protección de Rideshare"
      },
      {
        id: 10,
        text: "Seguro por muerte accidental y desmembramiento (AD&D)"
      },
      {
        id: 11,
        text: "Garantía extendida y protección al comprador"
      },
      {
        id: 12,
        text: "Descuentos en salud, compras, viajes y entretenimiento"
      }
    ]
  };

  const savingsListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Cuentas gratis para menores de hasta 18 años"
      },
      {
        id: 2,
        text: "Depósito inicial de $25 ($10 para menores de edad)"
      },
      {
        id: 3,
        text: "Sin cargo mensual ($3 por mes para saldos por debajo de $100)"
      },
      {
        id: 4,
        text: "El saldo mínimo para ganar intereses es $100&mdash; ($10 para menores); esta es una cuenta de tasa variable"
      }
    ]
  };

  const highYieldListData = {
    greenCircleCheckmark: true,
    items: [
      {
        id: 1,
        text: "Depósito inicial de $25"
      },
      {
        id: 2,
        text: "Esta es una cuenta de tasa variable"
      },
      {
        id: 3,
        text: "Producto a partir del 10/19/2022 la promocion puede suspenderse en cualquier momento"
      },
      {
        id: 4,
        text: "No disponible para cuentas IRA o clientes comerciales"
      }
    ]
  };

  const FreeCheckingData = () => (
    <>
      <StaticImage
        className="border-radius-bottom-0"
        src="../images/thumbnail-free-checking-050624.jpg"
        alt="Grupo de amigos disfrutando de un atardecer junto al lago"
        placeholder="blurred"
        loading="eager"
        quality="100"
      />
      <div className="p-3 mb-5 mb-sm-0">
        <h2>Cuenta de cheques gratuita</h2>
        <h4 className="text-success font-weight-bold">Consiga la mejor cuenta de cheques *gratuita</h4>
        <p className="font-weight-bold">Todo lo que puedes pedir en una cuenta de cheques gratuita:</p>
        <List {...freeCheckingListData} accountName={"es-free-checking"} />
        <p className="font-weight-bold">
          <Link id="es-fc-learn-more" to="/es/banca-personal/gratis-cuenta-de-cheques">
            Conoce más sobre la Cuenta de cheques gratuita
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
        <Button
          containerClass="mb-3"
          id="free-checking-open-account-cta"
          class="btn-primary"
          text="Abrir una cuenta"
          showIcon={false}
          url="/open-an-account"
        />
        <p className="text-sm text-muted">*Se pueden aplicar cargos de fondos insuficientes devueltos</p>
      </div>
    </>
  );

  const InterestCheckingData = () => (
    <>
      <StaticImage
        className="border-radius-bottom-0"
        src="../images/thumbnail-page-interest-checking-050624.jpg"
        alt="Hombre de compras sonriéndo con su teléfono en la mano"
        placeholder="blurred"
        loading="eager"
        quality="100"
      />
      <div className="p-3  mb-5 mb-sm-0">
        <h2>Interest Checking</h2>
        <h4 className="text-success font-weight-bold">Intereses, recompensas y más, todo en una cuenta</h4>
        <p className="font-weight-bold">¡Disfruta el acceso a beneficios equivalentes a más de $95 cada mes!:</p>
        <List {...interestCheckingListData} accountName={"es-interest-checking"} />
        <p className="font-weight-bold">
          <Link id="es-sc-learn-more" to="/es/banca-personal/interest-rewards-checking">
            Conoce más sobre la cuenta Interest Checking
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
        <Button
          containerClass="mb-3"
          id="interest-open-account-cta"
          class="btn-primary"
          text="Abrir una cuenta"
          showIcon={false}
          url="/open-an-account"
        />
        <p className="text-sm text-muted">*En las sucursales donde estén disponibles</p>
      </div>
    </>
  );

  const SavingsData = () => (
    <>
      <StaticImage
        className="border-radius-bottom-0"
        src="../../images/cards/card-savings.jpg"
        alt="Pareja caminando juntos bajo una sombrilla."
        placeholder="blurred"
        loading="eager"
        quality="100"
      />
      <div className="p-3  mb-5 mb-sm-0">
        <h2>Ahorros</h2>
        <h4 className="font-weight-bold text-success">
          Abra una cuenta de ahorros con las herramientas y servicios para ayudarle a prepararse para el futuro.
        </h4>
        <p className="font-weight-bold">Todo lo que necesitas en una cuenta de Ahorro:</p>
        <List {...savingsListData} accountName={"es-savings"} />
        <p className="font-weight-bold">
          <Link id="es-savings-learn-more" to="/es/banca-personal/cuentas-de-ahorro">
            Más sobre Ahorros
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
        <Button
          containerClass="mb-3"
          id="savings-btn"
          class="btn-primary"
          text="Abrir una cuenta"
          showIcon={false}
          url="/open-an-account"
        />
        <p className="text-sm text-muted">
          WaFd Bank no pide su reporte crediticio para esta aplicación.<sup>1</sup>
        </p>
      </div>
    </>
  );

  const HighYieldMoneyMarketData = () => (
    <>
      <StaticImage
        className="border-radius-bottom-0"
        src="../../images/cards/card-money-market.jpg"
        alt="Abuelo y su nieta contando el dinero de una alcancía de puerquito."
        placeholder="blurred"
        loading="eager"
        quality="100"
      />
      <div className="p-3  mb-5 mb-sm-0">
        <h2>Cuentas Money Market de Interés Alto</h2>
        <h4 className="font-weight-bold text-success">
          Las herramientas y los servicios para ayudarlo a generar riqueza con confianza
        </h4>
        <p className="font-weight-bold">Flexibilidad para administrar tu dinero:</p>
        <List {...highYieldListData} accountName={"es-high-yield-money-market"} />
        <p className="font-weight-bold">
          <Link id="es-mm-learn-more" to="/es/banca-personal/cuentas-de-ahorro">
            Más información sobre las cuentas del mercado de oro dinero
            <Icon name="arrow-right" class="ml-1" />
          </Link>
        </p>
        <Button
          containerClass="mb-3"
          id="high-yield-money-market-open-account-cta"
          class="btn-primary"
          text="Abrir una cuenta"
          showIcon={false}
          url="/open-an-account"
        />
        <p className="text-sm text-muted">
          WaFd Bank no pide su reporte crediticio para esta aplicación.<sup>1</sup>
        </p>
      </div>
    </>
  );

  const quickLinksData = [
    {
      name: "Préstamos Hipotecarios",
      text: "WaFd Bank es un prestamista de cartera que lo acompañará en cada paso del camino.",
      imageSource: "../../images/icons/mortgage-loans-icon-96.svg",
      altText: "Link a Préstamos Hipotecarios.",
      url: "/es/banca-personal/prestamos-hipotecarios"
    },
    {
      name: "Préstamos de Liquidez Hipotecaria",
      text: "Utilice el valor líquido de su vivienda para financiar su próximo gran proyecto.",
      imageSource: "../../images/icons/heloc-loans-icon-96.svg",
      altText: "Link a Préstamos de Liquidez Hipotecaria.",
      url: "/es/banca-personal/prestamos-hipotecarios#heloc"
    },
    {
      name: "Tarjetas de crédito",
      text: "Tarjetas de Credito WaFd con tarifas competitivas y grandes recompensas.",
      imageSource: "../../images/icons/credit-cards-icon-96.svg",
      altText: "Link a Tarjetas de crédito.",
      url: "/es/banca-personal/tarjetas-de-credito"
    }
  ];

  const SavingsTabData = () => (
    <div className="row row-cols-1 row-cols-sm-2">
      <div className="col-sm-6 card border-0 mb-3" id="savings-data-column">
        <div className="bg-light h-100 border-radius-12">
          <SavingsData />
        </div>
      </div>
      <div className="col-sm-6 card border-0 mb-3" id="high-yeild-money-market-data-column">
        <div className="bg-light h-100 border-radius-12">
          <HighYieldMoneyMarketData />
        </div>
      </div>
    </div>
  );

  const CheckingsTabData = () => (
    <div className="row row-cols-1 row-cols-sm-2">
      <div className="col-sm-6 card border-0 mb-3" id="free-checking-data-column">
        <div className="bg-light h-100 border-radius-12 ">
          <FreeCheckingData />
        </div>
      </div>
      <div className="col-sm-6 card border-0 mb-3" id="interest-checking-data-column">
        <div className="bg-light h-100 border-radius-12 ">
          <InterestCheckingData />
        </div>
      </div>
    </div>
  );

  const tabComponentData = {
    tabs: [
      {
        name: "Cuentas de Cheques",
        data: <CheckingsTabData />
      },
      {
        name: "Cuentas de Ahorro",
        data: <SavingsTabData />
      }
    ],
    defaultActiveTabIndex: 0
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Abrir Una Cuenta En Linea</h1>
        <div className="row">
          <div className="col-md-6">
            <h4 className="text-gray-80">
              ¿Está buscando abrir una cuenta corriente o de ahorros, o agregarla a sus cuentas existentes? WaFd Bank,
              nombrado el mejor banco grande de Newsweek por tres años consecutivos, ofrece cuentas de cheques con las
              mejores calificaciones y puede abrir una en minutos.
            </h4>
          </div>
          <div className="col-md-6">
            <h5>Esto es lo que necesitarás</h5>
            <ul>
              <li>
                Residir en los estados de Arizona, California, Idaho, Nevada, Nuevo México, Oregón, Texas, Utah o
                Washington.
              </li>
              <li>Método de financiación, como una tarjeta de crédito o débito, o enrutamiento y número de cuenta.</li>
            </ul>
          </div>
        </div>
      </section>
      <Tab {...tabComponentData} />
      <section className="bg-light" id="quick-links-section">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
            {quickLinksData.map((block, index) => {
              return (
                <div
                  key={index}
                  id={block.name.toLocaleLowerCase().replace(/ +/g, "-")}
                  className="text-center mb-3 px-2"
                >
                  <img src={block.imageSource} alt={block.altText} className="mb-3" />
                  <h3>{block.name}</h3>
                  <Link
                    to={block.url}
                    id={block.name.toLocaleLowerCase().replace(/ +/g, "-") + "-link"}
                    className="text-center mb-3 mb-lg-0 text-black text-decoration-none"
                  >
                    <span dangerouslySetInnerHTML={{ __html: block.text }} />
                    <Icon name="arrow-right" class="ml-1" />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};

export default OpenBankAccountOnlineSpanish;
